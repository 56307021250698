<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item v-if="this.userInfo.user.roles[0].id == 1" :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>资金管理</el-breadcrumb-item>
                    <el-breadcrumb-item>银行账户列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="cont">
            <div class="listbox">
                <div class="listright" v-loading="loading">
                    <div class="searchright">
                        <el-button size="small" type="primary" @click="openadd">+添加收款账户</el-button>
                    </div>
                    <div class="top"></div>
                    <el-table :data="list" :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}" style="width: 100%">
                        <el-table-column prop="accountName" label="账户名"></el-table-column>
                        <el-table-column prop="accountNo" label="账户"></el-table-column>
                        <el-table-column prop="bankName1" label="开户行名称"></el-table-column>
                        <el-table-column prop="type" label="账户类型">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.type == 1" type="info">clabe</el-tag>
                                <el-tag v-if="scope.row.type == 2" type="info">银行卡</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="jobName" :label="$t('operator')" width="120">
                            <template slot-scope="scope">
                                <el-button size="small" @click="openedit(scope.row)" type="primary" icon="el-icon-edit"
                                    circle plain style="margin-right:5px;"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <pop-up :title="status == 1 ? '添加收款账户信息' : '编辑收款账户信息'" :isshow="showadd" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box" style="width: 450px;">
                <el-form ref="form" :inline="true" :model="form" :rules="rules" size="small">
                    <el-form-item label="账户名" prop="accountName">
                        <el-input v-model="form.accountName" />
                    </el-form-item> 
                    <el-form-item label="账号" prop="accountNo">
                        <el-input v-model="form.accountNo"/>
                    </el-form-item>
                    <el-form-item label="账户类型" prop="type">
                        <el-select v-model="form.type" placeholder="请选择">
                            <el-option label="clabe" value="1"></el-option>
                            <el-option label="银行卡" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开户行名称" prop="type">
                        <el-select size="small" v-model="form.bankName" filterable :placeholder="$t('qxz')"  style="width:300px;">
                            <el-option :label="$t('qxz')" :value="null"></el-option>
                            <el-option v-for="item in accountlist"
                                :key="item.value"
                                :label="item.desc"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
    import apiurl from "@/api/public"
    import _api from "@/api/index"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    export default {
        components: {
            Treeselect
        },
        data() {
            var rolesValid = (rule, value, callback) => {
                callback()
            }
            return {
                form: {
                    id: null,
                    accountName: null,
                    accountNo: null,
                    type: null,
                    bankName:null,
                    nation:null,
                    orgId:null,
                },
                rules:{
                    applyName:[{ required: true, trigger: 'blur' },],
                    accountNo:[{ required: true, trigger: 'blur' },],
                    type:[{ required: true, trigger: 'blur' },],
                },
                defaultProps: {
                    label: "name",
                    isLeaf: 'isLeaf'
                },
                loading: false,
                showadd: false,
                accountlist: [],
                list: [],
                value1:[],
            }
        },
        created() {
            this.userInfo=JSON.parse(localStorage.getItem("userinfo"))
            this.getList()
            this.getaccount()
        },
        methods: {
            getaccount() {
                _api.get(apiurl.bankType, {
                }).then(res => {
                    this.accountlist = res.data
                })
            },
            openadd() {
                this.showadd = true
                this.status = 1
                this.form = {
                    id: null,
                    accountName: null,
                    accountNo: null,
                    type: null,
                    bankName:null,
                    nation:null,
                    orgId:null,
                }
            },
            openedit(row) {
                this.status = 2
                this.form.id = row.id
                this.form.accountNo = row.accountNo
                this.form.type = row.type
                this.form.accountName = row.accountName
                this.form.bankName = row.bankName
                this.form.nation = row.nation
                this.form.orgId = row.orgId
                this.showadd = true
            },
            getclose() {
                this.$refs.form.resetFields();
                this.showadd = false
            },
            handleNodeClick(mydata, node, obj) {
                this.loading = true
                this.getList()
            },
            deleteproduct(row) {
                _api.delete(apiurl.bank, row.id).then(res => {
                    this.$notify({
                        title: this.$t('success'),
                        message: this.$t('delSuccess'),
                        type: 'success'
                    });
                    this.getList()

                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    });
                })
            },
            getconfirm() {
                if (this.status == 1) { //如果是添加
                    _api.post(apiurl.bank, this.form).then(res => {
                        this.$notify.success({
                            title: this.$t('success'),
                            message: this.$t('addSuccess')
                        })
                        this.getList()
                        this.showadd = false
                        this.form = {
                            id: null,
                            accountName: null,
                            accountNo: null,
                            type: null,
                            bankName:null,
                            nation:null,
                            orgId:null,
                        }
                    }).catch(err => {
                        this.$notify.error({
                            title: this.$t('error'),
                            message: err.data.message
                        })
                    })
                } else { //如果是编辑
                    _api.put(apiurl.bank, this.form).then(res => {
                        this.$notify.success({
                            title: this.$t('success'),
                            message: this.$t('editSuccess')
                        })
                        this.getList()
                        this.showadd = false
                        this.form = {
                            id: null,
                            accountName: null,
                            accountNo: null,
                            type: null,
                            bankName:null,
                            nation:null,
                            orgId:null,
                        }
                    }).catch(err => {
                        this.$notify.error({
                            title: this.$t('error'),
                            message: err.data.message
                        })
                    })
                }
            },
            getList() {
                this.loading = true
                _api.get(apiurl.bank, {
                }).then(res => {
                    this.loading = false
                    this.list = res.data
                })
            },
            onSubmit() {
                this.getList()
            },
            
        }
    }
</script>
<style scoped>
    .lefttop {
        padding: 8px;
    }

    .listbox {
        display: flex;
    }

    .listleft {
        width: 20%;
        border: 1px solid #EBEEF5;
    }

    .listright {
        width: 100%;
        margin-left: 1%;
    }

    .top {
        margin-top: 15px;
    }

    .listrighttop {
        padding: 0 10px;
        background: rgb(245, 245, 245);
        height: 40px;
        overflow: hidden;
        line-height: 40px;
        border-bottom: 1px solid #EBEEF5;
    }

    .listrighttop strong {
        float: left;
    }

    .listrighttop span {
        float: right;
    }

    ::v-deep .vue-treeselect__control,
    ::v-deep .vue-treeselect__placeholder,
    ::v-deep .vue-treeselect__single-value {
        height: 30px;
        line-height: 30px;
    }
</style>